<template>
  <v-main>
    <login-page></login-page>
  </v-main>
</template>

<script>
const LoginPage = () => import(/* webpackChunkName: "loginpage" */ "@/views/login/LoginPage");

export default {
  name: 'Login',
  components: {
    LoginPage,
  }
}
</script>
