export default {
    state: ()=>({
        contentLoading: true,
    }),
    getters: {
      isLoading(state) {
          return state.contentLoading;
      }
    },
    mutations: {
        SET_CONTENT_LOADING(state, playload) {
            state.contentLoading = playload;
        },
    }
}