import Vue from 'vue';
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

let routes;
if (process.env.VUE_APP_BASE_OLD_ROUTER == 1) {
  routes = require('@/router/router-old');
} else {
  routes = require('@/router/router-new');
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_STATIC_PATH : "/",
  routes: routes.default
});

let first = true;
router.beforeEach((to, from, next) => {
  function clearParams() {
    if (!first)
      store.dispatch('clearParams');
    else
      first = false;
  }
  if (!store.getters.tokenData && store.getters.isLoggedIn)
    clearParams();

  if (process.env.VUE_APP_EMBEDDED == 1) {
    if (to.name === 'login') {
      router.push({path: '/dashboard'});
    } else {
      next();
    }
  } else {
    if (to.name !== 'login' && !store.getters.isLoggedIn) {
      next({name: 'login'});
    } else if (to.name === 'login' && store.getters.isLoggedIn) {
      router.push(from);
    } else {
      next();
    }
  }
});

router.afterEach((to) => {
  document.title = to.meta?.breadcrumb?.label || to.meta?.title || to?.name || to?.fullPath?.replace('/', '');
});

export default router;
