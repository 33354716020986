const defaultState = JSON.parse(localStorage.getItem('sidebarMini'));

export default {
    state: ()=>({
       // hide:
        mini: defaultState,
        menu: true
    }),
    getters: {
      isSidebarMini(state) {
          return state.mini;
      }
    },
    mutations: {
        SET_SIDEBAR_MINI(state, playload) {
            state.mini = playload;
        },
        SET_MENU_STATUS(state, playload) {
            state.menu = playload;
        }
    },
    actions: {
        toggleSidebarMini(context) {
            const mini = !context.state.mini;
            context.commit('SET_SIDEBAR_MINI', mini);
            localStorage.setItem('sidebarMini', mini);
        },
        toggleSidebarMenu(context) {
            const menu = !context.state.menu;
            context.commit('SET_MENU_STATUS', menu);
            localStorage.setItem('sidebarMenu', menu);
        }
    }
}