<template>
  <div class="pl-2 trend">
    <div class="d-flex justify-start"
         :class="[trend ? 'green--text' : 'red--text']">
      <strong class="subheader__stats-value font-weight-semi-bold">
        {{ value }}
      </strong>

      <v-img v-if="trend"
             class="ml-1 flex-grow-0" contain width="24" height="24" src="@/assets/icons/ic-arrow-up.svg"/>
      <v-img v-else class="ml-1 flex-grow-0" contain width="24" height="24" src="@/assets/icons/ic-arrow-down-red.svg"/>
    </div>
    <span class="subheader__stats-text">
      {{ !onlyText ? text : null }}
      {{!onlyText ? (trend ? ' Up by' : ' Down by') : onlyText}}
    </span>
  </div>
</template>

<script>
export default {
  name: "Trend",
  date() {
    return {
      status: true
    }
  },
  props: {
    value: String,
    text: String,
    onlyText: {
      type: String,
      default: null,
    }
  },
  computed: {
    trend() {
      if (!this.value) return true;
      return !this.value.trim().startsWith('-')
    }
  }
}
</script>

<style lang="scss" scoped>
  .trend {
    &__value {
      font-size: 20px;
      line-height: 24px;
    }

    &__text {
      font-size: 14px;
      line-height: 19px;
    }
  }
</style>