<template>
  <div>
    <TheHeader v-if="showSidebar"></TheHeader>
    <TheSideBar v-if="showHead"></TheSideBar>

    <v-main>
      <v-container class="px-4 py-0 fill-height" fluid>
        <v-row sm="6" class="pa-2" v-if="!showHead || $vuetify.breakpoint.mdAndDown">
          <v-col class="d-flex align-center" style="">
            <crumbs :key="$route.fullPath"></crumbs>
          </v-col>
          <v-col v-if="showHead" sm="6" class="d-flex align-center justify-end">

            <v-btn fab color="white" class="mr-4" small>
              <v-img contain height="14" width="18" :src="require('@/assets/icons/ic-message.svg')"/>
            </v-btn>

            <v-btn fab class="mr-4" color="white" small>
              <v-img contain height="22" width="19" :src="require('@/assets/icons/ic-alert.svg')"/>
            </v-btn>

          </v-col>
        </v-row>
        <v-row class="fill-height">
          <v-col>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
const TheSideBar = () => import(/* webpackChunkName: "the-sidebar" */ "@/components/TheSideBar");
const TheHeader = () => import(/* webpackChunkName: "the-header" */ "@/components/TheHeader");
const Crumbs = () => import(/* webpackChunkName: "crumbs" */ "@/components/ui/Crumbs");

export default {
  name: 'Default',
  data: () => ({
    showSidebar: Boolean(parseInt(process.env.VUE_APP_BASE_SIDEBAR)),
    showHead: Boolean(parseInt(process.env.VUE_APP_BASE_HEAD))
  }),
  components: {
    Crumbs,
    TheHeader,
    TheSideBar
  },
}
</script>
