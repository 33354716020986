import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import colors from 'vuetify/lib/util/colors'
//import { Ripple } from 'vuetify/lib/directives';
//Vuetify,
// import {
//     VCard,
//     VRating,
//     VToolbar,
// } from 'vuetify/lib'
// const mq = window.matchMedia('(prefers-color-scheme: dark)');
// console.log('mq', mq);

// const dark = {
//     "primary":{"base":"#1976d2","lighten5":"#c7fdff","lighten4":"#a8e0ff","lighten3":"#8ac5ff","lighten2":"#6aaaff","lighten1":"#488fef","darken1":"#005eb6","darken2":"#00479b","darken3":"#003180","darken4":"#001e67"},
//     "secondary":{"base":"#424242","lighten5":"#c1c1c1","lighten4":"#a6a6a6","lighten3":"#8b8b8b","lighten2":"#727272","lighten1":"#595959","darken1":"#2c2c2c","darken2":"#171717","darken3":"#000000","darken4":"#000000"},
//     "accent":{"base":"#82b1ff","lighten5":"#ffffff","lighten4":"#f8ffff","lighten3":"#daffff","lighten2":"#bce8ff","lighten1":"#9fccff","darken1":"#6596e2","darken2":"#467dc6","darken3":"#2364aa","darken4":"#004c90"},
//     "error":{"base":"#ff5252","lighten5":"#ffe4d5","lighten4":"#ffc6b9","lighten3":"#ffa99e","lighten2":"#ff8c84","lighten1":"#ff6f6a","darken1":"#df323b","darken2":"#bf0025","darken3":"#9f0010","darken4":"#800000"},
//     "info":{"base":"#2196f3","lighten5":"#d4ffff","lighten4":"#b5ffff","lighten3":"#95e8ff","lighten2":"#75ccff","lighten1":"#51b0ff","darken1":"#007cd6","darken2":"#0064ba","darken3":"#004d9f","darken4":"#003784"},
//     "success":{"base":"#4caf50","lighten5":"#dcffd6","lighten4":"#beffba","lighten3":"#a2ff9e","lighten2":"#85e783","lighten1":"#69cb69","darken1":"#2d9437","darken2":"#00791e","darken3":"#006000","darken4":"#004700"},
//     "warning":{"base":"#fb8c00","lighten5":"#ffff9e","lighten4":"#fffb82","lighten3":"#ffdf67","lighten2":"#ffc24b","lighten1":"#ffa72d","darken1":"#db7200","darken2":"#bb5900","darken3":"#9d4000","darken4":"#802700"},
//     "anchor":"#1976d2"
// }
Vue.use(Vuetify);

const opts = {
    // directives: {
    //     Ripple
    // },
    theme: {
        themes: {
            light: {
                primary: '#7ABCF5', //colors.orange.lighten3
                green: '#32AFAB',
                orange: '#FB9504',
                orange2: '#FD692A',
                secondary: '#f5f5f5', //	F9F9F9
                deepblue: '#1B203D',
                primaryblue: '#43435D',
                deepblue_light: '#f5f5f5',
                primaryblue_light: '#f5f5f5',
                process: '#6FCF97',

                header: '#F2F4F7',
                slider_main: '#DF0E77',
                tabs_main: '#DF0E77',
                loader: '#DF0E77',
                loader_back: '#e98cb7',
                //breadcrumbs: '#DF0E77',

                // secondary: colors.orange.lighten3,
                // accent: colors.orange.lighten3,
                // error: colors.orange.lighten3,
            },
            dark:{
                // accent: "#82B1FF",
                // deepblue: "#1B203D",
                // error: "#FF5252",
                // green: "#32AFAB",
                // info: "#2196F3",
                // orange: "#FB9504",
                // primary: "#7ABCF5",
                // primaryblue: "#43435D",
                // secondary: "#424242",
                // success: "#4CAF50",
                // warning: "#FB8C00"
            }
        },
    },
    // theme: {
    //     options: {
    //        // customProperties: true,
    //         // themeCache: {            // https://vuetifyjs.com/en/features/theme/#caching
    //         //     get: key => {
    //         //         console.log('get Theme', JSON.stringify(key));
    //         //         return localStorage.getItem(key)
    //         //     },
    //         //     set: (key, value) => {
    //         //         console.log('set Theme', key, value);
    //         //         return localStorage.setItem(key, value)
    //         //     },
    //         // },
    //     },
    //     dark: false,
    //         themes: {
    //           //  dark
    //         // dark: {
    //         //     primary: '#43435D',
    //         //         secondary: '#43435D',
    //         //         accent: '#43435D',
    //         // }
    //     }
    // }
}
export default new Vuetify(opts);
