<template>
  <v-select
    solo
    dense
    flat
    v-model="selected"
    :items="data"
    :label="label"
    class="dropdown-select"
    @change="setFilter"
  ></v-select>
</template>

<script>
export default {
  name: 'FilterSelect',
  data(){
    return {
      selectedLocal: null,
    }
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: 'Filter',
    },
    query: {
      type: String,
      default: 'fuck',
    }
  },
  computed: {
    selected: {
      get() {
        return this.selectedLocal;
      },
      set(nv) {
        this.selectedLocal = nv;
        this.$store.dispatch('updateParams', {[this.query]: nv})
      }
    }
  },
  created() {
    this.selectedLocal = this.$store.getters.params[this.query]; // this.data.find((item)=> item.value == )
  },
  methods: {
    setFilter(value) {
      this.$emit('setFilter', value);
      this.$emit('input', value);
    }
  },
  watch: {
    $route () { //to, from
      if (this.query && this.selected !== null)
        this.$store.dispatch('updateParams', {[this.query]: this.selectedLocal})
    }
  }
}
</script>
