import axios from 'axios';
import store from "@/store";

console.log('process.env.VUE_APP_BASE_URL', process.env.VUE_APP_BASE_URL);

const embeddedVersion = process.env.VUE_APP_EMBEDDED == 1;
let refreshTokenRequest = null;

const requestToken = async actionType => {
  let _token;
  if (refreshTokenRequest === null) {
    if (actionType === 'refreshToken') {
      refreshTokenRequest = store.dispatch('refreshToken');
    } else if (actionType === 'login' && embeddedVersion) {
      // identity_id and auth_key are global variables, located in first body script
      refreshTokenRequest = store.dispatch('login', {id: identity_id, auth_key: auth_key});
    }
  }
  _token = await refreshTokenRequest;
  refreshTokenRequest = null;
  return _token;
}

const requestValidAccessToken = async () => {
  let token;
  const now = Date.now();

  if (embeddedVersion && !store.getters.isLoggedIn) {
    token = await requestToken('login');
  }

  if (!store.getters.isTokenUrlValid) {
    await store.dispatch('logout');
    if (embeddedVersion) {
      token = await requestToken('login');
    } else {
      return;
    }
  }

  token = store.state.auth.tokenData.token;

  if (!store.getters.isRefreshTokenValid(now)) {
    store.dispatch('logout');
  } else if (!store.getters.isAccessTokenValid(now)) {
    token = await requestToken('refreshToken');
  }

  return token;
}

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use(
  async config => {
    if (config.skipAuth) {
      return config;
    }

    const accessToken = await requestValidAccessToken();

    return {
      ...config,
      headers: {
        common: {
          ['Authorization']: `Bearer ${accessToken}`,
        },
      },
    };
  }
);

httpClient.interceptors.response.use(
  response => {
    const {status} = response;

    if (status === 401) {
      store.dispatch('logout');
    }

    return response;
  },
  error => Promise.reject(error)
);

export const getData = async ({path, params = {}}) => { //customer_number
  const res = await httpClient.get(path, {params});
  if (res.status === 200) {
    return res.data;
  }
  new Error(res.config.baseURL + path + " Data is not loaded", res)
}

export default httpClient;
