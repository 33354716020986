import Vue from 'vue';
import FilterSelect from '@/components/ui/FilterSelect';

Vue.prototype.$dateOffset = [
    { value: 0, text: "Current day"},
    { value: 7, text: "Week" },
    { value: 30, text: "Month" },
    { value: 365, text: "Year" }
]

Vue.component('FilterSelect', FilterSelect);
