const DashboardPage = () => import(/* webpackChunkName: "dashboardpage" */ '@/views/dashboard/DashboardPage');
const LoginPage = () => import(/* webpackChunkName: "loginpage" */ '@/views/login/LoginPage');
// const Reports = () => import(/* webpackChunkName: "reports" */  '@/views/Reports');
// const Inbox = () => import(/* webpackChunkName: "inbox" */  '@/views/Inbox');
// const Stepper = () => import(/* webpackChunkName: "premiums" */  '@/views/Premiums');
// const Alerts = () => import(/* webpackChunkName: "alerts" */  '@/views/Alerts');
// const Shippments = () => import(/* webpackChunkName: "shippmentspage" */  '@/views/shippments/ShippmentsPage');
// const Analytics = () => import(/* webpackChunkName: "analyticspage" */  '@/views/analytics/AnalyticsPage');
// const Page = () => import(/* webpackChunkName: "page" */ '@/views/Page');

const routes = [
  {path: '/', redirect: '/dashboard/operation'},
  {path: '/login', name: 'login',  component: LoginPage, meta: {layout: 'login', title: 'Login'}},
  {path: '/dashboard', redirect: '/dashboard/operation'},
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardPage,
    children: [
      {
        name: 'dashboard-operation',
        path: '/dashboard/operation',
        meta: {breadcrumb: {parent: 'dashboard', label: 'Operation'}}
      },
      {name: 'dashboard-sales', path: '/dashboard/sales', meta: {breadcrumb: {parent: 'dashboard', label: 'Sales'}}},
      {
        name: 'dashboard-finance',
        path: '/dashboard/finance',
        meta: {breadcrumb: {parent: 'dashboard', label: 'Finance'}}
      },
    ],
    meta: {
      breadcrumb: {
        label: 'Dashboard',
      }
    }
  },
  //{path: '/dashboard/view', component: Inbox},
  // { path:'/inbox', component: Inbox, meta:{title: 'Inbox'} },
  // { path:'/new-products', component: Reports, meta:{title: 'Reports'}  },
  // { path:'/purchase', component: Stepper, meta:{title: 'Purchase'} },
  // { path:'/cad', component: Alerts, meta:{title: 'CAD Design Req'} },
  // { path:'/products', component: Page, meta:{title: 'Products'} },
  // { path:'/pricing', component: Page, meta:{title: 'Pricing'} },
  // { path:'/quality', component: Page, meta:{title: 'Quality'} },
  // { path:'/ticket', component: Page, meta:{title: 'Ticket'} },
  // { path:'/inventory', component: Page, meta:{title: 'Inventory'} },
  // { path:'/shippments', name: 'shippments', component: Shippments, meta:{title: 'Shippments'} },
  // { path:'/finance', component: Page, meta:{title: 'Finance'} },
  // { path:'/analytics', component: Analytics, meta:{title: 'Analytics'} },

]

export default routes;
