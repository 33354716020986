const DashboardPage = () => import('@/views/dashboard/DashboardPage');

const routes = [
    { path: '/', redirect: '/dashboard/index' },
    { path: '/dashboard', redirect: '/dashboard/index' },
    {
        path:'/dashboard',
        name: 'dashboard',
        component: DashboardPage,
        children: [
            {name: 'dashboard-operation', path: '/dashboard/index', meta:{breadcrumb: { parent: 'dashboard', label: 'Operation'}} },
            {name: 'dashboard-sales', path: '/dashboard/sales', meta:{breadcrumb: { parent: 'dashboard', label: 'Sales'}} },
            {name: 'dashboard-finance', path: '/dashboard/finance', meta:{breadcrumb: { parent: 'dashboard', label: 'Finance'}} },
        ],
        meta:{
            breadcrumb: {
                   label:'Dashboard',
            }
        }
    }
]

export default routes;
