import qs from 'query-string';
import router from "@/router";

function updateQuery(query) {
    history.pushState(null, null, query ? "?" + query : '');
}
const parsedQuery = qs.parse(location.search, { parseNumbers: true, parseBooleans: true }) || {};

console.log('parsedQuery', parsedQuery)
export default {
    state: ()=>({
        //blaaaaa: 1,
        params: {
         ...parsedQuery
        }
    }),
    getters: {
        params(state) {
            return state.params;
        }
    },
    mutations: {
        SET_PARAMS(state, params) {
            state.params = params;
        },
        ADD_PARAMS(state, params) {
            state.params = {...state.params, ...params};
        },
    },
    actions: {
        clearParams({ commit, state }) {
            commit('SET_PARAMS', {});
            console.log('clearParams state', state)
        },
        updateParams(context, params) {
            const { commit, state } = context;
            if (params) {
                let p = params;
                if (router.currentRoute.name === 'dashboard-operation' && params.date_offset !== undefined) {
                    p = {...state.params, ...params, date_offset: 0};
                }
                commit('ADD_PARAMS', p);
            }
            const str = qs.stringify(state.params);
            updateQuery(str);
           // console.log('str', str);
            //console.log('qs', qs, updateQuery, state);
            //console.log('state', state);
        }
    }
}
