<template>
  <div class="card-header" :class="[presetClass, paddings]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardHeader",
  props: {
    paddings: {
      type: String,
      default: '',
    },
    presetClass: {
      type: String,
      default: 'd-flex justify-space-between align-center'
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header {
  &__title {
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    font-weight: 700;
  }
}
</style>